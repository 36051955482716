<template>
  <div class="admin">
    <v-navigation-drawer class="admin-drawer" absolute>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Admin
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense tile nav>
        <div v-for="item in filteredAdminItems" :key="item.name">
          <v-list-item v-if="item.type != 'spacer'" link class="admin-list-item" @click="navigate(item.link)" v-bind:class="{ 'selected-link': isSelected(item) }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else class="admin-divider">
            <v-list-item-content>
              <v-divider></v-divider>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <router-view class="admin-router"/>
  </div>
</template>
<script>
export default {
  name: 'Admin',
  components: {
  },
  computed: {
    filteredAdminItems () {
      return this.adminItems.filter(i => !i.key || this.$store.getters.hasKey(i.key));
    }
  },
  data: () => ({
    adminItems: [
      {
        name: 'Dashboard',
        link: '/admin/dashboard',
        key: 'Dashboard'
      },
      {
        name: 'Analytics',
        link: '/admin/analytics',
        key: 'Analytics'
      },
      {
        type: 'spacer',
        key: 'Dashboard'
      },
      {
        name: 'Shows',
        link: '/admin/shows',
        key: 'ShowManagement'
      },
      {
        name: 'Episodes',
        link: '/admin/episodes',
        key: 'EpisodeManagement'
      },
      {
        name: 'Categories',
        link: '/admin/categories',
        key: 'CategoryManagement'
      },
      {
        name: 'Promotional',
        link: '/admin/promotional',
        key: 'CarouselManagement'
      },
      {
        name: 'Notifications',
        link: '/admin/notifications',
        key: 'Notifications'
      },
      {
        name: 'Livestream',
        link: '/admin/livestream',
        key: 'LivestreamManagement'
      },
      {
        type: 'spacer'
      },
      {
        name: 'Users',
        link: '/admin/users',
        key: 'UserManagement'
      },      {
        name: 'Coupons',
        link: '/admin/coupons',
        key: 'CouponManagement'
      },
      {
        name: 'Keys',
        link: '/admin/keys',
        key: 'RewardsManagement'
      },
      {
        type: 'spacer'
      },
      {
        name: 'Shareholders',
        link: '/admin/shareholders',
        key: 'ShareholderManagement'
      },
      {
        name: 'Content Ideas',
        link: '/admin/contentIdeas',
        key: 'ContentIdeasManagement'
      },
      {
        name: 'Investor Info',
        link: '/admin/investorInfo',
        key: 'InvestorInfoManagement'
      },
      {
        name: 'Financial Reports',
        link: '/admin/financialReports',
        key: 'FinanceManagement'
      },
      {
        name: 'Bulletins',
        link: '/admin/bulletins',
        key: 'BulletinManagement'
      },
      {
        name: 'Schedule',
        link: '/admin/schedule',
        key: 'ScheduleManagement'
      },
    ]
  }),
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    isSelected: function (item) {
      return this.$route.path == item.link;
    }
  }
}
</script>
<style lang="scss">
.admin {
  height: calc(100vh - 64px);
}
.admin-drawer {
  margin-top: 64px;
  height: calc(100vh - 64px) !important;
}
.admin-router {
  width: calc(100vw - 256px);
  margin-left: 256px;
}
.selected-link {
  background-color: #ffffff22;
}
.admin-list-item {
  border-radius: 0px !important;
}
.admin-divider {
  min-height: 8px !important;
  padding: 0px;
}
</style>
